* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  /* font-family: "Poppins", sans-serif; */
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: #818181;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #424242;
  /* font-family: "Playfair Display", serif; */
}
p {
  margin: 0;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a,
a:hover {
  text-decoration: none;
}

.map {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

[role="button"] {
  outline: none;
}

.fb_dialog {
  left: 18pt;
}

iframe.fb_customer_chat_bounce_in_v2 {
  left: 36pt !important;
}

iframe.fb_customer_chat_bounce_out_v2 {
  left: 36pt !important;
}

/*=============================
    Loader style
 =============================*/

.loader-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader-animation {
  position: relative;
  width: 100%;
  height: 4rem;
  overflow: hidden;
}

.ambulance-icon {
  position: absolute;
  left: -10%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: #009d02;
  animation: moveAmbulance 5s infinite linear;
}

@keyframes moveAmbulance {
  0% {
    left: -10%;
  }
  100% {
    left: 110%;
  }
}

.loader-text {
  margin-top: 1rem;
  font-size: 1.2rem;
}

/*=============================
    snap toggle style
 =============================*/

.snap-toggle {
  position: fixed;
  width: 2rem;
  height: 1.5rem;
  top: 95%;
  right: 1.25rem;
  color: #009d02;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 99999;
}

.snap-toggle.show {
  opacity: 1;
}

.snap-toggle .focus-ring-2:focus {
  --tw-ring-inset: 0;
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #000;
  --tw-shadow: 0 0 #0000;

  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

/*=============================
    general-style
 =============================*/
/*=== ostion-btn ===*/
.main-btn {
  font-size: 12px;
  text-transform: uppercase;
  background: #009d02;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0 30px 0 35px;
  line-height: 49px;
  position: relative;
  z-index: 1;
  display: inline-block;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  text-decoration: none;
  border: none;
}
.main-btn:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 1px;
  z-index: -1;
  background: #009d02;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  /* -webkit-transform: skew(-12deg, 0deg);
  -moz-transform: skew(-12deg, 0deg);
  -ms-transform: skew(-12deg, 0deg);
  -o-transform: skew(-12deg, 0deg); */
  /* transform: skew(-12deg, 0deg); */
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.main-btn:hover,
.main-btn:hover:after {
  background-color: #212121;
  color: #fff;
}
/*=== section-heading ====*/
.section-heading .section__title {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
}
.section-heading .section__meta {
  color: #863bae;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 8px;
  display: block;
}
/*===text__white ====*/
.section-heading .text__white {
  color: #fff;
}
/*=== section-icon ====*/
.section-icon {
  margin-bottom: 9px;
  line-height: 1px;
}
/*=== section-divider ====*/
.section-divider {
  height: 2px;
  background-color: #f2f0ec;
  position: relative;
  display: block;
}
/*=== loading-area ===*/
#loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
}
#loading-area .loader-box #loader-1 {
  -webkit-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#loading-area .loader-box #loader-1:before {
  content: "";
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  background-color: #ff7607;
  -webkit-animation: flip 1s infinite;
  animation: flip 1s infinite;
}
#loading-area .loader-box .loader {
  width: 100px;
  height: 100px;
  position: relative;
}
@keyframes flip {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}

/*===================================
    container
=====================================*/
.container {
  max-width: 1200px;
}

/*===================================
    HEADER AREA
=====================================*/
.header-area .header-top-action {
  background: #f2f0ec;
  line-height: 32px;
  position: relative;
  z-index: 2;
}
.header-area .header-top-action .top-action-content {
  background: #424242;
  position: relative;
  z-index: 1;
}
.header-area .header-top-action .info-action-content {
  background-color: transparent;
}
.header-area .header-top-action .info-action-content:before,
.header-area .header-top-action .info-action-content:after {
  display: none;
}
.header-area .header-top-action .top-action-content:before,
.header-area .header-top-action .top-action-content:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #424242;
  position: absolute;
  top: 0;
  right: 100%;
  z-index: -1;
}
.header-area .header-top-action .top-action-content:after {
  right: -20px;
  -webkit-transform: skew(-12deg, 0deg);
  -moz-transform: skew(-12deg, 0deg);
  -ms-transform: skew(-12deg, 0deg);
  -o-transform: skew(-12deg, 0deg);
  transform: skew(-12deg, 0deg);
}
.header-area .header-top-action .top-action-content .info-box ul li {
  color: #fff;
  font-size: 13px;
  margin-right: 30px;
}
.header-area .header-top-action .top-action-content .info-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.header-area .header-top-action .top-action-content .info-box ul li:last-child,
.header-area
  .header-top-action
  .top-action-content
  .info-box
  ul.top-action-list
  li:last-child {
  margin-right: 0;
}
.header-area .header-top-action .top-action-content .info-box ul li a {
  color: #fff;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.header-area .header-top-action .top-action-content .info-box ul li a:hover,
.header-area .header-top-action .top-action-content .info-box ul li a i {
  color: #f1ae44;
}
.header-area .header-top-action .top-action-content .info-box ul li a i {
  margin-right: 5px;
  font-size: 12px;
}
.header-area
  .header-top-action
  .top-action-content
  .info-box
  ul.top-action-list
  li
  a {
  color: #818181;
  text-transform: capitalize;
}
.header-area
  .header-top-action
  .top-action-content
  .info-box
  ul.top-action-list
  li {
  margin-right: 24px;
}
.header-area
  .header-top-action
  .top-action-content
  .info-box
  ul.top-action-list
  li
  a:hover,
.header-area
  .header-top-action
  .top-action-content
  .info-box
  ul.top-action-list
  li
  a
  i:hover {
  color: #e36955;
}
.header-area
  .header-top-action
  .top-action-content
  .info-box
  ul.top-action-list
  li
  a
  i {
  color: #818181;
  font-size: 14px;
}

/*=== header-top ====*/
.header-area .header-top {
  background-color: #fff;
  height: 8rem;
}
.header-area .header-top .ostion-top-wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.header-area .header-top .ostion-top-wrap .ostion-logo {
  height: 120px;
  display: table;
  vertical-align: middle;
}
.header-area .header-top .ostion-top-wrap .ostion-logo a {
  height: 120px;
  display: table-cell;
  vertical-align: middle;
}
.header-area .header-top .ostion-top-wrap .header-btn .main-btn,
.header-area .header-top .ostion-top-wrap .header-btn .main-btn:after {
  background-color: #009d02;
}
.header-area .header-top .ostion-top-wrap .header-btn .main-btn:hover,
.header-area .header-top .ostion-top-wrap .header-btn .main-btn:hover:after {
  background-color: #212121;
}
.header-area .header-top .ostion-top-wrap .ostion-menu {
  height: 120px;
  position: relative;
}
.header-area .header-top .ostion-top-wrap .ostion-menu .mobile-menu-toggle {
  position: absolute;
  right: 15px;
  top: 0;
  color: #232323;
  font-size: 21px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background-color: rgba(35, 35, 35, 0.1);
  line-height: 37px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: none;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .mobile-menu-toggle:hover {
  background-color: #ff7607;
  color: #fff;
}
/*==== main-navigation =====*/
.header-area .header-top .ostion-top-wrap .ostion-menu .ostion-menu-content {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 16px;
  height: 120px;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li {
  display: inline-block;
  position: relative;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li:last-child
  a {
  margin-right: 0;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li:hover
  .dropdown-menu-item {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
/*=== dropdown menu ===*/
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  .dropdown-menu-item {
  position: absolute;
  left: 0;
  top: 120%;
  min-width: 230px;
  background-color: #fff;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
  z-index: 3;
  -webkit-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 0 30px;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  .dropdown-menu-item
  li {
  padding-bottom: 16px;
  display: block;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  .dropdown-menu-item
  li:first-child {
  padding-top: 16px;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  .dropdown-menu-item
  li
  a {
  padding: 0 0;
  margin: 0;
  line-height: 14px;
  display: inline-block;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  .dropdown-menu-item
  li
  a:after {
  bottom: -3px;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li.active
  > a {
  color: #424242;
  margin-left: 0;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  a {
  color: #818181;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin: 0 22px;
  padding-bottom: 17px;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  position: relative;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  a:after {
  content: "";
  height: 1px;
  width: 0;
  background: #424242;
  position: absolute;
  bottom: 18px;
  right: 0;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  a:hover {
  color: #424242;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .main-navigation
  ul
  li
  a:hover:after {
  right: auto;
  left: 0;
  width: 100%;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .header-social {
  flex: 1;
  float: right;
  font-size: 16px;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .header-social
  ul
  li {
  margin-right: 28px;
  display: inline-block;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .header-social
  ul
  li:last-child {
  margin-right: 0;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .header-social
  ul
  li
  a
  i.fa-facebook {
  color: #182eb2;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .header-social
  ul
  li
  a
  i.fa-twitter {
  color: #31a5ff;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .header-social
  ul
  li
  a
  i.fa-youtube-play {
  color: #ff2e2e;
}
.header-area
  .header-top
  .ostion-top-wrap
  .ostion-menu
  .ostion-menu-content
  .navigation-top
  .header-social
  ul
  li
  a
  i.fa-google-plus {
  color: #232323;
}

/*==== header-info ====*/
.header-area .header-info {
  margin-bottom: -62px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  height: 62px;
}
.header-area .header-info .header-info-inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 61px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: -2px;
  margin-right: -4px;
}
.header-area .header-info .header-info-inner .header-info-box {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  height: 60px;
  width: 33%;
  display: flex;
  align-items: center;
}
.header-area
  .header-info
  .header-info-inner
  .header-info-box.header-info-box-1 {
  border-left: none;
  text-align: left;
}
.header-area
  .header-info
  .header-info-inner
  .header-info-box.header-info-box-2 {
  text-align: center;
  justify-content: center;
}
.header-area
  .header-info
  .header-info-inner
  .header-info-box.header-info-box-3 {
  justify-content: flex-end;
}
.header-area .header-info .header-info-inner .header-info-box i {
  color: #ff7607;
  margin-right: 10px;
  font-size: 16px;
  margin-top: -3px;
}

/*==== mobile menu ===*/
.header-area .side-nav-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  background-color: #232323;
  overflow-x: hidden;
  z-index: 999;
  -webkit-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
  -moz-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
  -ms-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
  -o-transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
  transition: all 0.4s cubic-bezier(0.7, 0, 0.2, 1);
  -webkit-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -moz-box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.header-area .side-nav-container.active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.header-area .side-nav-container .humburger-menu .humburger-menu-lines {
  display: inline-block;
  text-align: right;
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 18px;
  opacity: 0.5;
}
.header-area .side-nav-container .humburger-menu .humburger-menu-lines:before,
.header-area .side-nav-container .humburger-menu .humburger-menu-lines:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 10px;
  left: 0;
  background-color: #fff;
  -webkit-transform: rotate(-43deg);
  -moz-transform: rotate(-43deg);
  -ms-transform: rotate(-43deg);
  -o-transform: rotate(-43deg);
  transform: rotate(-43deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header-area .side-nav-container .humburger-menu .humburger-menu-lines:after {
  -webkit-transform: rotate(43deg);
  -moz-transform: rotate(43deg);
  -ms-transform: rotate(43deg);
  -o-transform: rotate(43deg);
  transform: rotate(43deg);
}
.header-area
  .side-nav-container
  .humburger-menu
  .humburger-menu-lines:hover:before,
.header-area
  .side-nav-container
  .humburger-menu
  .humburger-menu-lines:hover:after {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.header-area .side-nav-container .side-menu-wrap {
  margin-top: 100px;
}
.header-area .side-nav-container .side-menu-wrap .side-menu-ul .sidenav__item {
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item.active
  .menu-plus-icon:before {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item:hover
  > a {
  background-color: rgba(255, 255, 255, 0.12);
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  a {
  font-size: 16px;
  color: #fff;
  display: block;
  text-transform: capitalize;
  font-weight: 500;
  padding: 10px 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 45px;
  line-height: 50px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:before,
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:after {
  position: absolute;
  top: 24px;
  right: 20px;
  content: "";
  width: 11px;
  height: 2px;
  background-color: #fff;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .menu-plus-icon:after {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li
  a {
  padding-left: 30px;
  padding-top: 9px;
  padding-bottom: 9px;
  color: rgba(255, 255, 255, 0.7);
}
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-menu-ul
  .sidenav__item
  .side-sub-menu
  li
  a:hover {
  color: #ff7607;
}
/*=== side-social ===*/
.header-area .side-nav-container .side-menu-wrap .side-social {
  margin-top: 30px;
  text-align: center;
}
.header-area .side-nav-container .side-menu-wrap .side-social li {
  display: inline-block;
  padding-right: 12px;
}
.header-area .side-nav-container .side-menu-wrap .side-social li:last-child {
  padding-right: 0;
}
.header-area .side-nav-container .side-menu-wrap .side-social li a {
  font-size: 14px;
  background-color: #50bac3;
  color: #fff;
  padding: 0 5px 0 12px;
  line-height: 42px;
  position: relative;
  z-index: 1;
  display: block;
  min-width: 35px;
}
.header-area .side-nav-container .side-menu-wrap .side-social li a:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #50bac3;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-12deg, 0deg);
  -moz-transform: skew(-12deg, 0deg);
  -ms-transform: skew(-12deg, 0deg);
  -o-transform: skew(-12deg, 0deg);
  transform: skew(-12deg, 0deg);
}
.header-area .side-nav-container .side-menu-wrap .side-social li:nth-child(2) a,
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-social
  li:nth-child(2)
  a:after,
.header-area .side-nav-container .side-menu-wrap .side-btn .main-btn,
.header-area .side-nav-container .side-menu-wrap .side-btn .main-btn:after {
  background-color: #e36955;
}
.header-area .side-nav-container .side-menu-wrap .side-social li:nth-child(3) a,
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-social
  li:nth-child(3)
  a:after {
  background-color: #f1ae44;
}
.header-area .side-nav-container .side-menu-wrap .side-social li:nth-child(4) a,
.header-area
  .side-nav-container
  .side-menu-wrap
  .side-social
  li:nth-child(4)
  a:after {
  background-color: #863bae;
}
.header-area .side-nav-container .side-menu-wrap .side-btn {
  text-align: center;
  margin-top: 40px;
}
/*==== header-fixed ====*/
.header-fixed {
  background-color: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.7s ease;
  -moz-transition: 0.7s ease;
  -ms-transition: 0.7s ease;
  -o-transition: 0.7s ease;
  transition: 0.7s ease;
}

/*===================================
        SLIDER AREA
 =====================================*/
.slider-area {
  position: relative;
  z-index: 1;
  cursor: grab;
}
.slider-area .single-slide-item {
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  color: #fff;
  height: 600px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.slider-area .slider-img {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  background-color: #242424;
  object-fit: cover;
}

.slider-area .slide-item-table {
  display: table;
  width: 100%;
  height: 100%;
}
.slider-area .slide-item-tablecell {
  display: table-cell;
  vertical-align: middle;
}

/*=== slider-heading ===*/
.slider-area .slider-heading .slider__meta {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 1px solid #fff;
  display: inline-block;
  margin-bottom: 12px;
}
.slider-area .slider-heading .slider__title {
  font-size: 100px;
  line-height: 107px;
  color: #fff;
  font-weight: 900;
  margin-bottom: 47px;
}
/*=== owl-nav ===*/
.slider-area .homepage-slide1 .owl-dots {
  position: absolute;
  top: 50%;
  right: 250px;
  z-index: 9999;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.slider-area .homepage-slide1 .owl-dots div {
  width: 14px;
  height: 20px;
  margin-bottom: 10px;
  background-color: #fff;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: relative;
  border: none;
  padding: 0;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.slider-area .homepage-slide1 .owl-dots div:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fff;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-25deg, 0deg);
  -moz-transform: skew(-25deg, 0deg);
  -ms-transform: skew(-25deg, 0deg);
  -o-transform: skew(-25deg, 0deg);
  transform: skew(-25deg, 0deg);
}

.slider-area .homepage-slide1 .owl-dots div.active,
.slider-area .homepage-slide1 .owl-dots div:hover {
  opacity: 1;
}

/*===================================
    MIXER AREA
=====================================*/
.mixer-area {
  position: relative;
  z-index: 1;
  /* background-image: url("../../images/slider4.jpg"); */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-top: 120px;
  padding-bottom: 120px;
}
.mixer-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(66, 66, 66, 0.9);
  z-index: -1;
}
.mixer-area .mixer-heading .section__title {
  font-size: 70px;
  line-height: 84px;
  margin-bottom: 48px;
  font-weight: 800;
}
/*===================================
    MIXER AREA 2
=====================================*/
.mixer-area2 {
  padding-top: 108px;
  position: relative;
  z-index: 1;
}
.fun-content-wrap .fun-content .fun-item {
  border-bottom: 2px solid #f2f0ec;
  margin-right: 117px;
  position: relative;
  padding-left: 104px;
  margin-bottom: 50px;
  padding-bottom: 56px;
}
.fun-content-wrap .fun-content .fun-item2 {
  margin-bottom: 0;
}
.fun-content-wrap .fun-content .fun-item i {
  font-size: 65px;
  line-height: 65px;
  color: #424242;
  position: absolute;
  left: 0;
  top: 12px;
}
.fun-content-wrap .fun-content .fun-item h3 {
  font-size: 50px;
  line-height: 55px;
  font-weight: 800;
  margin-bottom: 13px;
}
.fun-content-wrap .fun-content .fun-item p {
  color: #e36955;
}

/*=== fun-content-slide ===*/
.mixer-area2 .fun-content-slide {
  background-color: #e36955;
  margin-top: -344px;
  padding: 115px 0 100px 118px;
  position: relative;
  z-index: 1;
}
.mixer-area2 .fun-content-slide .fun-slide-item {
  color: #fff;
}
.mixer-area2 .fun-content-slide .fun-slide-item .icon-quote {
  font-size: 170px;
  height: 50px;
  line-height: 110px;
  /* font-family: "Playfair Display", sans-serif; */
  margin-bottom: 40px;
  margin-left: -7px;
}
.mixer-area2 .fun-content-slide .fun-slide-item .funslide__text {
  font-size: 40px;
  line-height: 54px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 43px;
}
.mixer-area2 .fun-content-slide .fun-slide-item .funslide__name {
  color: #424242;
  font-weight: 600;
  text-transform: uppercase;
}
/*=== mixer owl-dots ===*/
.mixer-area2 .owl-dots {
  position: absolute;
  width: 50px;
  right: -148px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.mixer-area2 .owl-dots div {
  width: 11px;
  height: 17px;
  display: inline-block;
  margin: 0 10px;
  background-color: #fff;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: relative;
  border: none;
  padding: 0;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.mixer-area2 .owl-dots div:hover,
.mixer-area2 .owl-dots div:hover:after,
.mixer-area2 .owl-dots div.active,
.mixer-area2 .owl-dots div.active:after {
  background-color: #fff;
  opacity: 1;
}
.mixer-area2 .owl-dots div:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #fff;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-25deg, 0deg);
  -moz-transform: skew(-25deg, 0deg);
  -ms-transform: skew(-25deg, 0deg);
  -o-transform: skew(-25deg, 0deg);
  transform: skew(-25deg, 0deg);
}
/*===================================
    MIXER AREA 3
=====================================*/
.mixer-area3:after {
  background-color: black;
}
.mixer-area3 .section-heading {
  padding-right: 70px;
  padding-left: 70px;
}
/*===================================
    MIXER AREA 4
=====================================*/
.mixer-area4 .section-heading {
  padding-right: 70px;
  padding-left: 0;
}
.mixer-area4 .section-heading .section__meta {
  color: #f1ae44;
}
.mixer-area4 .section-heading .section__desc {
  line-height: 30px;
  margin-top: 42px;
  margin-bottom: 35px;
}
.mixer-area4 .section-heading .main-btn,
.mixer-area4 .section-heading .main-btn:after {
  background-color: #e36955;
}
.mixer-area4 .section-heading .main-btn:hover,
.mixer-area4 .section-heading .main-btn:hover:after {
  background-color: #f1ae44;
}
.mixer-area4 .mixer-video-content {
  position: relative;
}
.mixer-area4 .mixer-video-content img {
  width: 100%;
}
.mixer-area4 .mixer-video-content .video-play-btn {
  font-size: 12px;
  background-color: #f1ae44;
  color: #fff;
  font-weight: 600;
  width: 50px;
  height: 60px;
  cursor: pointer;
  line-height: 60px;
  text-align: center;
  position: absolute;
  padding-left: 5px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  display: inline-block;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.mixer-area4 .mixer-video-content .video-play-btn:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #f1ae44;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-12deg, 0deg);
  -moz-transform: skew(-12deg, 0deg);
  -ms-transform: skew(-12deg, 0deg);
  -o-transform: skew(-12deg, 0deg);
  transform: skew(-12deg, 0deg);
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.mixer-area4 .mixer-video-content .video-play-btn:hover,
.mixer-area4 .mixer-video-content .video-play-btn:hover:after {
  background-color: #e36955;
}
/*=============================
   GALLERY AREA
 =============================*/
.gallery-area {
  position: relative;
  z-index: 1;
  padding-top: 120px;
  padding-bottom: 112px;
}
.gallery-area:before,
.gallery-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 525px;
  /* background-color: rgba(66, 66, 66, 0.94); */
  background-color: #ffffff;
}
.gallery-area:after {
  /* background-image: url(../../images/mixer-bg.jpg); */
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.gallery-area .container {
  max-width: 1430px;
}
.gallery-area .section-heading .section__meta {
  color: #bfbfbf;
}
.gallery-area .gallery-wrap {
  margin-top: 51px;
}
.gallery-area .gallery-wrap .gallery-item,
.gallery-area2 .gallery-item {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.gallery-area .gallery-wrap .gallery-item:after,
.gallery-area2 .gallery-item:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: -87px;
  background-color: rgba(134, 59, 174, 0.9);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-11deg, 0deg);
  -moz-transform: skew(-11deg, 0deg);
  -ms-transform: skew(-11deg, 0deg);
  -o-transform: skew(-11deg, 0deg);
  transform: skew(-11deg, 0deg);
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  opacity: 0;
  visibility: hidden;
}
.gallery-area .gallery-wrap .gallery-item:hover:after,
.gallery-area2 .gallery-item:hover:after {
  width: 100%;
}
.gallery-area .gallery-wrap .gallery-item:hover:after,
.gallery-area .gallery-wrap .gallery-item:hover a,
.gallery-area2 .gallery-item:hover:after,
.gallery-area2 .gallery-item:hover a {
  opacity: 1;
  visibility: visible;
}
.gallery-area .gallery-wrap .gallery-item img {
  width: 100%;
}
.gallery-area .gallery-wrap .gallery-item a,
.gallery-area2 .gallery-item a {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 40px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}
.gallery-area .gallery-wrap .gallery-item .gallery-icon,
.gallery-area2 .gallery-item .gallery-icon {
  position: relative;
  width: 30px;
  height: 2px;
  display: inline-block;
}
.gallery-area .gallery-wrap .gallery-item .gallery-icon:before,
.gallery-area .gallery-wrap .gallery-item .gallery-icon:after,
.gallery-area2 .gallery-item .gallery-icon:before,
.gallery-area2 .gallery-item .gallery-icon:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
}

.gallery-area .gallery-carousel .owl-dots {
  width: 100%;
  margin-top: 58px;
  text-align: center;
}
.gallery-area .gallery-carousel .owl-dots div {
  width: 11px;
  height: 16px;
  margin-left: 7px;
  margin-right: 7px;
  background-color: #c6c6c6;
  display: inline-block;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: relative;
  border: none;
  padding: 0;
  z-index: 1;
}
.gallery-area .gallery-carousel .owl-dots div:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #c6c6c6;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  -webkit-transform: skew(-25deg, 0deg);
  -moz-transform: skew(-25deg, 0deg);
  -ms-transform: skew(-25deg, 0deg);
  -o-transform: skew(-25deg, 0deg);
  transform: skew(-25deg, 0deg);
}
.gallery-area .gallery-carousel .owl-dots div.active,
.gallery-area .gallery-carousel .owl-dots div:hover,
.gallery-area .gallery-carousel .owl-dots div:hover:after,
.gallery-area .gallery-carousel .owl-dots div.active:after {
  background-color: #424242;
  opacity: 1;
}
/*=============================
   GALLERY AREA
 =============================*/
.gallery-area2 {
  padding-top: 120px;
  padding-bottom: 90px;
}
.gallery-area2 .gallery-item {
  margin-bottom: 30px;
  position: relative;
}
.gallery-area2 .gallery-item img {
  width: 100%;
}
.gallery-area2 .gallery-item:after {
  left: -76px;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 30px;
}

.card-body-cascade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.01rem;
}

.team2 h5 {
  line-height: 20px;
  font-size: 18px;
  display: flex;
  justify-content: center;
}

.team2 .font-weight-medium {
  font-weight: 500;
}

.subtitle {
  color: #8d97ad;
  line-height: 24px;
  font-size: 13px;
  display: flex;
  justify-content: center;
}

.team2 .card {
  padding: 2px;
  cursor: pointer;
}
.mixer-area .card:hover,
.gallery-area .card:hover,
.team2 .card:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease-in-out;
}

.team-modal .modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.team-modal .bio {
  font-style: italic;
}

.team-modal ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.team-modal li {
  margin-bottom: 10px;
}

.team-modal h4 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.team-modal p {
  margin-bottom: 20px;
}

.team-modal .modal-footer {
  border-top: none;
}

.team-modal button.close {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.team-modal button.close:hover {
  opacity: 1;
}

.team2 .search-bar {
  max-width: 300px;
  margin: 0 auto;
  margin-right: 30px;
}

.team2 .search-bar .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.team2 .search-bar .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.team2 .form-select {
  display: block;
  width: 20%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  height: 50px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.team2 .form-select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

.team2 .form-select option {
  background-color: #fff;
  color: #495057;
}

.team2 .form-select option:checked {
  background-color: #e9ecef;
  color: #495057;
}

/*=============================
    Stories Component
 =============================*/

.stories-carousel {
  margin-top: 50px;
}

.story-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  background-color: #f7f7f7;
  overflow: hidden;
  cursor: pointer;
}

/* .story-card:hover {
  transform: translateY(-6px);
} */

.story-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2%;
  position: relative;
}

.story-title {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(10px, -10px);
  font-size: 36px;
  font-weight: bold;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  z-index: 2;
}

.story-desc {
  font-weight: 400;
}

.bg-info {
  background-image: url("../../images/slider2.jpg") !important;
  background-position: center;
  background-size: cover;
}

.contact4 {
  background-color: #f9f9f9;
  /* padding: 2px 0; */
}

.contact4 .container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 50px;
  position: relative;
}

.contact4 .form-control {
  background-color: #f9f9f9;
  border-radius: 4px;
  border: none;
  box-shadow: none;
  color: #666;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 20px;
  transition: all 0.3s;
}

.contact4 .form-control:focus {
  background-color: #fff;
  box-shadow: none;
}

.contact4 .form-control::-webkit-input-placeholder {
  color: #bbb;
}

.contact4 .form-control::-moz-placeholder {
  color: #bbb;
}

.contact4 .form-control:-ms-input-placeholder {
  color: #bbb;
}

.contact4 .form-control::placeholder {
  color: #bbb;
}

.contact4 textarea.form-control {
  height: 120px;
}

.contact4 .btn {
  background-color: #009d02;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 15px 30px;
  transition: all 0.3s;
}

.contact4 .btn:hover {
  background-color: #007d01;
}

.contact4 .icon {
  background-color: #009d02;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
  text-align: center;
  width: 50px;
}

@media (max-width: 992px) {
  .contact4 .container {
    padding: 30px;
  }

  .contact4 .form-control {
    font-size: 16px;
  }

  .contact4 textarea.form-control {
    height: 100px;
  }

  .contact4 .btn {
    font-size: 18px;
    padding: 10px 20px;
  }

  .contact4 .icon {
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
    width: 40px;
  }
}

@media (max-width: 576px) {
  .contact4 .container {
    padding: 20px;
  }

  .contact4 .form-control {
    font-size: 14px;
    margin-bottom: 15px;
    padding: 15px;
  }

  .contact4 textarea.form-control {
    height: 80px;
  }

  .contact4 .btn {
    font-size: 16px;
    padding: 10px 15px;
  }

  .contact4 .icon {
    font-size: 18px;
    height: 30px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .contact4 .container {
    padding: 30px;
  }

  .contact4 .form-control {
    font-size: 16px;
  }

  .contact4 textarea.form-control {
    height: 100px;
  }

  .contact4 .btn {
    font-size: 18px;
    padding: 10px 20px;
  }

  .contact4 .icon {
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
    width: 40px;
  }
}

.contact4 .contact-info {
  margin-bottom: 30px;
}

.contact4 .contact-info h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact4 .contact-info p {
  font-size: 18px;
  margin-bottom: 10px;
}

.contact4 .contact-info a {
  color: #009d02;
  font-size: 18px;
}

.contact4 .contact-info a:hover {
  text-decoration: underline;
}

/*=============================
    Custom Footer
 =============================*/

.custom-footer {
  background-color: #fff;
  color: #555;
  font-size: 16px;
  padding: 60px 0;
}

.custom-footer h4 {
  color: #222;
  font-size: 20px;
  margin: 0 0 20px;
}

.footer-title {
  font-weight: 700;
  text-transform: uppercase;
}

.footer-text {
  line-height: 1.8;
  /* margin-bottom: 30px; */
}

.footer-social-icons li {
  display: inline-block;
  margin-right: 10px;
}

.footer-social-icons a {
  color: #555;
  display: block;
  height: 40px;
  line-height: 60px;
  text-align: center;
  width: 40px;
}

.footer-social-icons a:hover {
  color: #222;
}

.footer-links {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #555;
  display: block;
  line-height: 1.8;
}

.footer-links a:hover {
  color: #222;
}

.footer-links svg {
  display: inline-block;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}

.footer-links span {
  display: inline-block;
  vertical-align: middle;
}

.footer-form {
  display: flex;
  margin-bottom: 30px;
}

.footer-form input[type="email"] {
  border: 2px solid #ddd;
  border-radius: 3px;
  font-size: 16px;
  padding: 10px;
  width: 100%;
}

.footer-form button[type="submit"] {
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  margin-left: 10px;
  padding: 5px;
  background-color: #009d02;
}

.footer-form button[type="submit"]:hover {
  background-color: #212121;
}

.footer-divider {
  border-top: 1px solid #ddd;
  margin: 60px 0;
}

.footer-text {
  font-size: 14px;
  line-height: 1.8;
}

.footer-links li:first-child {
  margin-top: 0;
}

@media (max-width: 479px) {
  .custom-footer {
    padding: 30px 0;
  }

  .footer-title {
    font-size: 18px;
    margin-top: 2rem !important;
  }

  .footer-text,
  .footer-links a {
    font-size: 14px;
  }

  .footer-social-icons li,
  .footer-links li {
    margin-bottom: 5px;
  }

  .footer-form input[type="email"] {
    margin-bottom: 5px;
  }
}

@media (max-width: 767px) {
  .custom-footer {
    padding: 40px 0;
  }

  .footer-title {
    margin-top: 0;
  }

  .footer-social-icons {
    margin-bottom: 30px;
  }

  .footer-links li {
    margin-bottom: 0;
  }

  .footer-form {
    flex-wrap: wrap;
  }

  .footer-form input[type="email"] {
    margin-bottom: 10px;
    width: 100%;
  }

  .footer-form button[type="submit"] {
    margin-left: 0;
    width: 100%;
  }
}

/*=============================
    Donate Page
 =============================*/

.donate-page .background-image {
  background-image: url("../../images/slider2.jpg") !important;
  background-size: cover;
  background-position: center center;
  filter: brightness(50%);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.donate-page .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.donate-page .donation-form {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.donate-page .donation-form label {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.donate-page .donation-form .form-control:focus {
  border-color: #007c01;
  box-shadow: none;
}

.donate-page .donation-form .btn-primary {
  background-color: #007c01;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 20px;
  width: 100%;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.donate-page .donation-form .btn-primary:hover {
  background-color: #0056b3;
}

.donate-page .donation-form .dropdown-label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.donate-page .donation-form .input-group-text {
  background-color: transparent;
  border: none;
  color: #0056b3;
}

@media (max-width: 576px) {
  .donate-page .donation-form {
    max-width: 100%;
    padding: 20px;
  }
}

.donate-page .input-group-text {
  background-color: #ffffff;
  border: none;
  border-radius: 3px;
  color: #222222;
}

.donate-page .form-control:focus {
  border-color: #222222;
  box-shadow: none;
}

.donate-page .btn-primary {
  background-color: #222222;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #ffffff;
  cursor: pointer;
}

.donate-page .btn-primary:hover {
  background-color: #555555;
}

.donate-page .qr-code-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  max-width: 300px;
  margin: 0 auto;
  margin-top: 20px;
}

.donate-page .qr-code-card:hover {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
}

.donate-page .qr-code-card img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.donate-page .qr-code-card p {
  color: #666666;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: 20px;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #428bca;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
  z-index: 2;
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.pagination > li.disabled > span,
.pagination > li.disabled > a,
.pagination > li.disabled > a:hover,
.pagination > li.disabled > span:hover,
.pagination > li.disabled > a:focus,
.pagination > li.disabled > span:focus {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 18px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
}

.page-link:hover,
.page-link:focus {
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.ngo-desc p {
  /* font-family: "Playfair Display", sans-serif; */
}

.ngo-desc .styled-link {
  color: #007d01;
  text-decoration: underline;
  display: flex;
  justify-content: center;
}

.mixer-area2 p {
  color: black !important;
  /* font-family: "Playfair Display", sans-serif; */
  font-weight: 200;
}

.about-us-ngo p {
  margin-bottom: 1.5em;
  margin-top: 0;
}

.about-us-ngo .about-us-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  color: #121212;
  margin: 0 0 15px;
}

.about-us-ngo li {
  margin-top: 5px;
}

.notice-container {
  padding: 40px 0;
}

.notice-card {
  background-color: #f8f8f8;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.notice-card h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.notice-card .social-icons {
  margin-right: 10px;
}

.notice-card img {
  width: 100%;
  margin-bottom: 20px;
}

.notice-card p {
  line-height: 1.5;
}

.news-title {
  font-size: 24px;
  text-align: center;
  margin-top: 20px;
}

.blog-card {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 0;
  /* height: 300px; */
}
.blog-card2 {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 0;
  height: 400px;
}

.blog-card2 .blog-card-content {
  position: relative;
  height: 100%;
}

.blog-card2 .blog-card-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.blog-card2 .blog-notice-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-card .date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 97px;
  height: 97px;
  background: var(--primary, #007d01);
  position: absolute;
  top: 150px;
  left: 10px;
}

.event-card .date .date-header {
  color: var(--light, #fff);
  font-family: Lora;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.event-card .date .month {
  color: var(--light, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.event-details-modal {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    background-color: #f8f9fa;
    border: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 20px;
    text-align: center;
  }

  .modal-title {
    color: #333;
    font-weight: bold;
    font-size: 28px;
    margin: 0;
  }

  .modal-body {
    padding: 20px;
  }

  .event-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }

  .event-date {
    font-size: 16px;
    margin-bottom: 15px;
    color: #555;
  }

  .event-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .event-content {
    font-size: 16px;
    line-height: 1.6;
    color: #444;
  }

  .modal-footer {
    border-top: none;
    text-align: center;
    padding: 20px;
  }

  .close-button {
    background-color: #009d02;
    font-size: 18px;
    font-weight: bold;
    padding: 12px 40px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .close-button:hover {
    background-color: #007c01;
  }
}

.blog-card2 .btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.blog-card:hover,
.blog-card2:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.blog-notice-card-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.blog-card-image {
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.blog-card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.blog-card-text {
  font-size: 16px;
  margin-bottom: 10px;
}

.blog-card-button {
  font-size: 14px;
  border-radius: 0;
  position: absolute;
  bottom: 20px;
}

.loader {
  animation: spin 1s linear infinite;
  font-size: 32px;
  margin: 50px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* TESTIMONIALS */

.testimonials-section {
  background-color: #fff;
  padding: 108px 0;
  text-align: center;
}

.testimonials-section .section-title,
.testimonials-section .section-sub-head {
  font-size: 54px;
  font-weight: 700;
  line-height: normal;
}

.testimonials-section .section-sub-head {
  color: var(--primary, #007d01);
}

.testimonials-section .section-line {
  width: 200px;
  height: 3px;
  background: #007d01;
  margin: 0 auto;
  margin-bottom: 30px;
}

.testimonials-section .section-subtitle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 871px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.testimonial-card {
  border: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s ease;
  padding: 30px;
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

.card-body {
  padding: 20px;
}

.card-text {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.5;
}

.ratings {
  margin-bottom: 10px;
}

.star-icon {
  color: #fbc02d;
  font-size: 20px;
}

.card-footer {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
}

.profile-picture {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  width: 60px;
  height: 60px;
  padding: 8px;
}

.profile-icon {
  color: #888;
  font-size: 36px;
}

.user-name {
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
}

.profile-image {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 50%;
  object-fit: cover;
}

/*=============================
   Events
 =============================*/

.ngo-events {
  padding: 40px 0;
}

.ngo-events .section-title {
  text-align: center;
  margin-bottom: 30px;
}

.ngo-events .event-card {
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.ngo-events .event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ngo-events .event-card .card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ngo-events .event-card .card-text {
  margin-bottom: 15px;
}

.ngo-events .event-card .btn {
  width: 100%;
}

.ngo-events .pagination {
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .ngo-events .event-card {
    margin-bottom: 30px;
  }
}

.news-card-body {
  height: 300px;
  overflow: hidden;
}

/*=============================
  Join Us / Jobs Section
 =============================*/

.section-job-list {
  padding: 60px 0;
}

.section__title {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.filter-form {
  padding: 20px 0;
}

.form-group label {
  font-weight: bold;
}

.job-list__wrapper {
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.job-list__wrapper h3 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.job-list__wrapper .card {
  transition: transform 0.3s ease;
  border: 1px solid #e5e5e5 !important;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden;
}

.job-list__wrapper .card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.job-list__wrapper .color--heading {
  display: flex;
  align-items: center;
}

.job-list__wrapper .badge {
  font-size: 14px;
  padding: 6px 10px;
  border-radius: 50%;
  background-color: #f48c06;
  color: #fff;
  margin-right: 10px;
}

.job-list__wrapper .color--text {
  font-size: 14px;
  color: #777;
}

.job-list__wrapper .fa-clock,
.job-list__wrapper .fa-map-marker-alt {
  margin-right: 5px;
  color: #999;
}

.job-list__wrapper .text-center {
  text-align: center;
}

.custom-select {
  appearance: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 8px 8px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #333;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select option {
  background-color: #fff;
  color: #333;
}

.section-job-list .form-group label {
  font-weight: bold;
  color: #555;
  display: block;
  margin-bottom: 0.5rem;
}

.section-job-list .badge {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 6px 10px;
  border-radius: 20px;
}

.section-job-list .badge-circle {
  border-radius: 50%;
}

.section-job-list .background--danger {
  background-color: #009d02;
  color: #fff;
}

@media (max-width: 767px) {
  .section__title {
    font-size: 32px;
  }

  .job-list__wrapper .color--heading {
    flex-wrap: wrap;
  }
}

/* Responsive Styles */
@media (max-width: 991px) {
  .section__title {
    font-size: 36px;
  }

  .job-list__wrapper {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .section__title {
    font-size: 28px;
  }

  .job-list__wrapper {
    padding: 10px;
  }
}

.gallery4 .text-center {
  text-align: center;
  margin-bottom: 30px;
}

.gallery4 .container {
  padding: 10px;
}

/*=============================
    Video Card
 =============================*/

.gallery4 .video-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.video-card__title {
  font-size: 24px;
  margin-bottom: 10px;
}

.video-card__description {
  color: #777;
  margin-bottom: 20px;
}

.video-card__iframe-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  overflow: hidden;
}

.video-card__thumbnail-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-card__thumbnail-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.play-btn:hover {
  opacity: 1;
}

.play-icon {
  color: #fff;
  font-size: 40px;
}

.header-image {
  background-position: center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-content {
  text-align: center;
  color: #fff;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  margin-top: 2rem;
}

/*=============================
    Job Details
 =============================*/

.job-detail-container {
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}

.job-detail-container h2 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
}

.job-detail-container h3 {
  margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
}

.job-detail-container ul {
  margin-bottom: 2rem;
  list-style-type: disc;
  padding-left: 1.5rem;
}

.job-detail-container li {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #666;
}

.job-detail-container button {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  background-color: #007d01;
  border-color: #007bff;
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;
}

.job-detail-container button:hover {
  background-color: #0062cc;
  border-color: #005cbf;
}

/*=============================
    Job Apply Now
 =============================*/

.custom-modal .modal-backdrop {
  opacity: 0.7;
  background-color: #000;
  transition: opacity 0.3s ease;
}

.custom-modal .modal-backdrop.show {
  opacity: 0.8;
}

.custom-modal .modal-dialog {
  max-width: 800px;
}

.custom-modal .modal-header .close {
  font-size: 1.5rem;
  color: #fff;
  opacity: 0.8;
  position: relative;
  top: 3px;
}

.custom-modal .modal-header .close:hover {
  opacity: 1;
}

.custom-modal .modal-content {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.custom-modal .modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-modal .modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-modal .modal-body {
  padding: 20px;
  background-color: #f8f9fa;
}

.custom-modal .modal-body p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.custom-modal .modal-footer {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 15px;
  background-color: #f8f9fa;
}

.custom-modal .modal-footer button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-modal button:hover {
  background-color: #0056b3;
}

.custom-modal .form-label {
  font-weight: bold;
}

.custom-modal .form-group {
  margin-bottom: 15px;
}

.custom-modal .form-group:last-child {
  margin-bottom: 0;
}

.custom-modal .form-select {
  height: 45px;
}

.custom-modal .form-control {
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

/* Custom styles for invalid form controls */
.custom-modal .form-control:invalid {
  border-color: #dc3545;
}

.custom-modal .invalid-feedback {
  display: block;
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 5px;
}

.custom-modal button {
  margin: auto;
  display: block;
  margin-top: 10px;
}

.custom-modal {
  cursor: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.custom-modal .modal-dialog {
  animation: fadeIn 0.3s ease;
}

@keyframes slideIn {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.custom-modal.show .modal-content {
  animation: slideIn 0.3s ease;
}

.custom-modal {
  cursor: auto;
}

@media (max-width: 576px) {
  .custom-modal .modal-body {
    padding: 15px;
  }

  .custom-modal .form-select {
    height: 40px;
  }
}

/* PAGE NOT FOUND */
.page-not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  background-color: #f4f7fa;
}

.page-not-found-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.page-not-found-breadcrumb {
  font-size: 1rem;
  color: #777;
  margin-bottom: 30px;
}

.page-not-found-image img {
  max-width: 100%;
  height: auto;
}

.page-not-found-button {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .page-not-found-title {
    font-size: 2rem;
  }

  .page-not-found-breadcrumb {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
}

/* CAUSES */

/* Style for Causes Component */
.causes-component {
  text-align: center;
  padding-top: 108px;
}

.causes-component .container {
  margin-top: 50px;
}

.causes-title,
.causes-sub-head {
  font-size: 54px;
  font-weight: 700;
  line-height: normal;
}

.causes-sub-head {
  color: var(--primary, #007d01);
}

.causes-line {
  width: 200px;
  height: 3px;
  background: #007d01;
  margin: 0 auto;
  margin-bottom: 30px;
}

.causes-component p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 871px;
  margin: 0 auto;
  margin-bottom: 30px;
}

/* Style for Cause Card */
.causes-component .card {
  border-radius: 5px;
  border: none;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin-bottom: 20px; /* Add margin between cards */
}

/* Adjust the card hover transform */
.causes-component .card:hover {
  transform: translateY(-5px) scale(1.01);
}

.causes-component .card .card-img {
  width: 100%;
  height: 271px;
  border-radius: 5px 5px 0px 0px;
  object-fit: cover;
  transition: transform 0.3s ease-in;
}

.causes-component .card:hover .card-img {
  transform: scale(0.95);
}

.causes-component .card .card-title {
  color: #000;
  font-family: Lora;
  font-size: 21px;
  font-weight: 700;
  line-height: 1.2;
  margin: 15px 0;
}

.causes-component .card .card-text {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
}

.causes-component .card .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
  border-top: none;
  padding: 15px;
  border-radius: 0px 0px 5px 5px;
}

.causes-component .card .card-footer h6,
.causes-component .card .card-footer .component-conjuct,
.causes-component .card .card-footer .cause-percentage {
  font-family: Lora, Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
}

.causes-component .card .card-footer h6 {
  color: #007d01;
}

.causes-component .card .card-footer .component-conjuct,
.causes-component .card .card-footer .cause-percentage {
  color: #666;
}

.causes-component .card .card-footer .cause-percentage {
  text-align: right;
}

.causes-component .card .card-footer div {
  width: 60%;
  height: 6px;
  border-radius: 4px;
  background: #007d01;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .causes-component .card {
    margin-bottom: 15px;
  }

  .causes-component .card .card-img {
    height: 180px;
  }
}

/* Responsive Styles */
@media (min-width: 940px) and (max-width: 1200px) {
  .causes-component .card .card-footer .component-conjuct {
    font-size: 12px; /* Further adjust font size */
    margin-top: 3px; /* Further adjust margin */
  }
}

/* PARTNERS */

.partners {
  background-color: #fff;
  padding: 60px 0;
  text-align: center;
  overflow: hidden;
}

.partners-title,
.partners-sub-head {
  font-size: 54px;
  font-weight: 700;
  line-height: normal;
}

.partners-sub-head {
  color: var(--primary, #007d01);
}

.partners-line {
  width: 200px;
  height: 3px;
  background: #007d01;
  margin: 0 auto;
  margin-bottom: 30px;
}

.partners-subtitle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 871px;
  margin: 0 auto;
  margin-bottom: 30px;
}

/*=============================
    Testimonials Page
 =============================*/

/* .testimonials-page {
  width: 1920px;
  height: 2702px;
} */

.testimonials-page .feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 300px; /* Adjust as needed */
  margin: 0 auto; /* Center the feedback block */
}

.testimonials-page .image {
  border-radius: 50%; /* Make the image circular */
  background: var(--secondary, #333);
  width: 120px;
  height: 120px;
  margin-bottom: 10px; /* Add a small gap between image and content */
}

.testimonials-page .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonials-page p {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 300px; /* Adjust as needed */
  margin: 0 auto; /* Center the text */
}

.testimonials-page .line {
  width: 16px;
  height: 2px;
  background: var(--primary, #007d01);
  margin: 15px auto; /* Center the line and add margin for spacing */
}

.testimonials-page .author {
  color: var(--primary, #007d01);
  font-family: Lora;
  font-size: 21px;
  font-weight: 700;
  margin: 5px 0; /* Add space between author and label */
}

.testimonials-page .label {
  color: #666;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

/*=============================
    Causes Single Page
 =============================*/

.urgent-cause {
  overflow: hidden;
}

.urgent-cause .left .title {
  width: 535px;
  color: #007c01;
  font-family: Lora;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.urgent-cause .left .description {
  width: 526px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  margin-top: 20px;
}

.urgent-cause .left .brochure {
  display: flex;
  align-items: center;
  width: 370px;
  height: 113px;
  background: #f1f1f1;
  padding: 10px;
  margin-left: 70px;
}

.urgent-cause .left .brochure h6 {
  margin-bottom: 5px;
  color: #007c01;
  font-family: Lora;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
}

.urgent-cause .left .brochure p {
  margin-bottom: 10px;
  color: var(--Secondary, #333);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.urgent-cause .left .brochure span {
  margin-left: auto;
  color: var(--Secondary, #333);
  font-family: Font Awesome 5 Free;
  font-size: 60px;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  align-items: center;
}

/* Styles for screens up to 768px wide */
@media screen and (max-width: 768px) {
  .urgent-cause .left .title {
    width: 100%;
    font-size: 36px;
    line-height: 1.2;
  }

  .urgent-cause .left .description {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
  }

  .urgent-cause .left .brochure {
    width: 100%;
    margin: 10px 0;
    padding: 5px;
  }

  .urgent-cause .left .brochure h6 {
    font-size: 18px;
    line-height: 1.2;
  }

  .urgent-cause .left .brochure p {
    font-size: 14px;
    line-height: 20px;
  }

  .urgent-cause .left .brochure span {
    font-size: 40px;
  }
  .urgent-cause .right .card {
    display: none;
  }

  .urgent-cause .right .btn {
    display: none;
  }
}

/* Styles for screens larger than 768px wide */
@media screen and (min-width: 769px) {
  .urgent-cause .left .title {
    width: 535px;
    font-size: 54px;
    line-height: normal;
  }

  .urgent-cause .left .description {
    width: 526px;
    font-size: 20px;
    line-height: 28px;
    margin-top: 20px;
  }

  .urgent-cause .left .brochure {
    width: 370px;
    margin-left: 70px;
    padding: 10px;
  }

  .urgent-cause .left .brochure h6 {
    font-size: 21px;
    line-height: normal;
  }

  .urgent-cause .left .brochure p {
    font-size: 16px;
    line-height: 24px;
  }

  .urgent-cause .left .brochure span {
    font-size: 60px;
  }

  .urgent-cause .left .btn {
    display: none;
  }
}
